.App {
  display: flex;
  flex-direction: column;
  height: 100%;
  text-align: center;
  .controls {
    position: relative;
    z-index: 10;
    width: 100vw;
    height: 100vh;
    .start-button {
      position: absolute;
      bottom: 40px;
      left: 40px;
      color: #fff;
      font-size: 24px;
    }
    .start-message {
      position: absolute;
      top: 42%;
      margin: 0 30px;
      background-color: #bbb;
      padding: 8px 8px;
      border-radius: 5px;
      font-size: 16px;
      text-align: left;
      font-weight: bold;
    }
  }
}

.container {
  flex-grow: 1;

  position: relative;
  height: 100%;
  width: 100vw;
  overflow: hidden;
  video {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 106vw;
  }
}

.target-container {
  position: relative;
  height: 100vh;
  .target-image {
    height: 60%;
    background-position: top center;
    background-repeat: no-repeat;
    background-size: contain;
    margin: 20px 20px 0px 20px;
  }
  h1 {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 1.6rem;
    background-color: #bbb;
    margin: 0 37px 0 37px;
    padding: 10px 0;
  }
  .restart-button {
    position: absolute;
    bottom: 40px;
    left: 40px;
    color: #fff;
    font-size: 24px;
  }
}

.aperture {
  height: 100%;
  display: grid;
  grid-template-rows: 30% 20% 20% 30%;
  grid-template-columns: 15% 70% 15%;
  grid-template-areas:
    "tt tt tt"
    "ll mt rr"
    "ll mb rr"
    "bb bb bb";
}

@each $area in tt, ll, rr, bb, mt, mb {
  .aperture__#{$area} {
    grid-area: $area;
    background-color: rgba(155, 155, 155, 0.77);
    z-index: 1;
  }
}

.aperture__mt,
.aperture__mb {
  transform: scaleY(1);
  transition: transform 0.3s ease-in;
  background-color: transparent;
}

.aperture__mt {
  transform-origin: top;
}

.aperture__mb {
  transform-origin: bottom;
}

.aperture--closed {
  .aperture__mt,
  .aperture__mb {
    transform: scaleY(0);
  }
}
